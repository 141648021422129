import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    redirect: '/tiktok'
  },
  {
    path: "/tiktok",
    name: "TikTok",
    component: () => import('../views/TikTok.vue'),
  },
  {
    path: "/instagram",
    name: "Instagram",
    component: () => import('../views/Instagram.vue'),
  },
  {
    path: "/twitter",
    name: "Twitter",
    component: () => import('../views/Twitter.vue'),
  },
  {
    path: "/youtube",
    name: "Youtube",
    component: () => import('../views/Youtube.vue'),
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
